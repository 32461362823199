/* You can add global styles to this file, and also import other style files */



html, body { 
    height: 100%;  
    /* https://stackoverflow.com/questions/28643617/bootstrap-modal-makes-scrollbar-disappear-after-closing */
    /* overflow: inherit !important; */
    /* overflow-y: scroll !important; */
}

body { 
    margin: 0; 
}

.offer-card-greeting-handset{
    width: 90%;
    height: 230px;
    margin: auto;
    margin-top: 15px;
    text-align: center;
    transition: all 0.5s;
    overflow: hidden;
}

.offer-card{
    min-width: 500px;
    max-width: 1140px;
    height: 290px;
    height: auto;
    margin: auto;
    margin-top: 15px;
    text-align: center;
    transition: all 0.5s;
}

.handset-portriat .offer-card{
    width: 400px !important;
    height: 1140px !important;
    margin: auto;
    margin-top: 15px;
    text-align: center;
    transition: all 0.5s;
}

.card-content-greeting{
    margin-right: 25px; 
    margin-left: 25px;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.card-content-greeting-handset{
    margin-top: 10px;
    margin-right: auto; 
    margin-left: auto;
}

.greeting-txt{
    text-align: left; 
}

.greeting-txt-handset{
    text-align: left; 
    font-size: small;
}

@container (min-width: 200px){
    .greeting-txt-handset{
        font-size: clamp(12.5px, calc(.80cqw + .80cqh), 20px);
    }
}

.container{
    container-type: inline-size;
}


.button-close {

    height: 40px; 
    width: 40px;
    margin-bottom: -1px;
    margin-right: -16px !important;

    background-color: transparent;
    border-top-right-radius: 7px;

}

.button-close:hover {
    color: #fff;
    background-color: #cc0000;
    cursor: pointer; 
    /* box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px); */
}

.image-close {
    margin: auto; 
    display: block; 
    color : white;
    margin-top: 10px;
    margin-left: 10px;
    width: 50%;
    height: 50%;
    margin-right: auto;
    border-top-right-radius: 7px;
}

.button-theme-temp{
    background-color: #dd1d54 !important;
    color: whitesmoke !important;
}

.button-request-terms{
    padding-left: 25px;
    padding-right: 25px;
}

.button-request {
    appearance: none;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
    display: inline-block;
    font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 16px;
    /* font-weight: 600; */
    line-height: normal;
    min-height: 35px;
    min-width: 0;
    outline: none;
    margin-top: 0px;
    margin-left: 16px;
    padding: 0px 0px 2px !important;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 125px;
    will-change: transform;
  }
  
  .button-request:disabled {
    pointer-events: none;
  }
  
  .button-request:hover {
    color: #fff;
    background-color: #1A1A1A;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }
  
  .button-request:active {
    box-shadow: none;
    transform: translateY(0);
  }
  
  .button-request-expanded{
      /* margin-top: 715px !important; */
      width: 125px;
      transition: all 0.5s;
  }
  
  .button-request-contracted{
      margin-top: 112px; 
      width: 125px;
      transition: all 0.5s;
  }
  
.card-title-price-handset{
    margin-left: auto; 
    margin-right: 0;
    display: inline;
    float: right;
    text-align: right;
}

.card-header-price-handset{
    height: 20%;      
    width: 90%;
    display: inline;
    float: right;
}

.search-form {

    min-width: 400px;
    max-width: 1140px;
    height: 50px;
    margin: auto;
    margin-top: 15px;
    text-align: center;

  }

  .search-form-handset {
    width: 90% !important;
    height: 50px;
    margin: auto;
    margin-top: 15px;
    text-align: center;
  }

  .input-search{
    padding-left: 15px;
    /* color: white !important; */
    font-family: inherit;
    height: 50px;
    width: 100%;
    /* background-color: #2d2d2d; */
    margin-bottom: 30px;
}

  .backdrop-blur{
    margin-left: -40vw;
    margin-top: -40vh;
    min-height: 200vh;
    height: 200vh;
    width: 138vw; 
    position: absolute; 
    z-index: -1;
    background-color: rgba(255, 255, 255, 0.05) !important;
    -webkit-backdrop-filter: blur(8px) !important;
    backdrop-filter: blur(8px) !important;
}


.cc-btn-custom{

    display: inline-block;
    font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    min-height: 35px;
    min-width: 0;
    outline: none;
    margin-top: 0px;
    margin-left: 16px;
    padding: 0px 0px 2px !important;
    text-align: center !important;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 125px;
    will-change: transform;



}

.cc-btn-custom:disabled {
    pointer-events: none;
  }
  
  .cc-btn-custom:hover {
    color: #fff;
    background-color: #1A1A1A;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }
  
  .cc-btn-custom:active {
    box-shadow: none;
    transform: translateY(0);
  }

  .cc-allow-custom{
    appearance: none;
    border: none;
    border-radius: 5px;
    border-color: #77b300 !important;
    box-sizing: border-box;
    color:white !important;
    background-color: #77b300 !important;
}

  .cc-deny-custom{
    appearance: none;
    border: none;
    border-radius: 5px;
    border-color: rgb(33, 33, 33) !important;
    box-sizing: border-box;
    color:white !important;
    background-color: rgb(33, 33, 33) !important;
  }

/* 
.card-content-paragraph-handset{
    margin-right: auto; margin-left: auto;
    text-align: left;
    width: 90%;
    margin-bottom: 20px;
    color:white;
    text-overflow: ellipsis;
    word-wrap:normal;
}
 */

